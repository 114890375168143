import { useEffect, useState } from 'react';
import axios from 'axios';

export function useAxiosLoaded(url, log = false) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function loadData() {
      const res = await axios(url);
      if (log) console.log(res);

      if (res.data.length === 0) {
        console.log('oups! something went wrong', res);
        setError(true);
        return;
      }

      const fetchData = res.data;
      setData(fetchData);
    }

    loadData();
  }, [url, log]);

  // useEffect(() => {
  // }, [data]);
  return [data, error];
}
