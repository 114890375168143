import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faClock,
  faParking,
  faBook
} from '@fortawesome/free-solid-svg-icons';
import { Newsletter } from './Newsletter';
import { Link, useHistory } from 'react-router-dom';
import { MapBox } from 'components/Mapbox';

export const Footer = ({ options }) => {
  const history = useHistory();
  const [loc, setLoc] = useState(history.location.pathname);

  history.listen((location, action) => {
    setLoc(location.pathname);
  });

  if (!options) return null;
  const { tel, email, logo_light } = options;

  return (
    <>
      {loc.search('kontakt') === 1 || loc.search('speisekarte') === 1 ? null : (
        <div style={{ height: '400px' }}>
          <MapBox />
        </div>
      )}

      <footer className="footer bg-primary white">
        <div className="container-big row justify-space-between">
          <div className="col-md-3">
            <a href="/">
              <img
                className="logo"
                src={logo_light.sizes.large}
                alt="logo-white"
              />
            </a>
          </div>
          <div className="col-md-3 contact">
            <h4 className="uppercase align-left fw">Öffnungszeiten</h4>
            <p className="row flex-align-start">
              <FontAwesomeIcon icon={faClock} />
              <span style={{ width: 'calc(100% - 30px)' }}>
                {/* <span>
                  Die Burgarena & Burgschenke verabschiedet sich in die
                  Winterpause und öffnet Mitte Mai 2024 wieder ihre Tore. <br />
                  <br />
                  Wir freuen uns schon jetzt auf Ihren Besuch und auf die
                  spannenden Veranstaltungen, die wir für die kommende Saison
                  planen. Bis bald!
                </span> */}
                {/* <span>
                  <b>Öffnungszeiten:</b>
                </span> */}
                {/* <br /> */}
                <span>Dienstag bis Sonntag ab 10 Uhr</span>
                {/* <br />
                <span style={{ marginBottom: '8px' }}>
                  Küche: 11:30 – 18:30 Uhr
                </span>
                <span>Sonntag: 11:00 - 18:00 Uhr</span>
                <br />
                <span style={{ marginBottom: '8px' }}>
                  Küche: 11:30 – 17:30 Uhr
                </span>
                <hr
                  style={{
                    width: '100%',
                    height: '1px',
                    background: 'white',
                    marginBottom: '8px',
                  }}
                />
                <span style={{ marginBottom: '8px' }}>
                  Am 18. Oktober geschlossene Gesellschaft
                </span>
                <span style={{ marginBottom: '8px' }}>
                  <b>Öffnungszeiten 22. Oktober 2023</b>
                  <br />
                  11:00 - 17:00 Uhr
                  <br />
                  Küche: 11:30 - 16:30 Uhr
                </span>
                <span style={{ width: '100%' }}>
                  Ab 23. Oktober geschlossen Saisonschluss
                </span>
                <br />
                <br />
                Wir behalten uns das Recht vor bei Schlechtwetter/Regen
                kurzeitig früher zu schließen */}
              </span>
            </p>
            <p className="row flex-align-start">
              <FontAwesomeIcon icon={faParking} />
              <span style={{ width: 'calc(100% - 30px)' }}>
                Eintritt frei und gratis Parkplätze
              </span>
            </p>
          </div>
          <div className="col-md-3 contact">
            <h4 className="uppercase fw">{options.contact_title}</h4>
            {/* <p>
              <FontAwesomeIcon icon={faUser} />
              Projektleitung Gastronomie & Hospitality
            </p> */}
            <p className="row flex-align-start">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <span style={{ width: 'calc(100% - 30px)' }}>
                {options.address}
              </span>
            </p>
            <p className="link" href={tel.url}>
              <FontAwesomeIcon icon={faBook} />
              <span>
                Reservierungen sind erst ab 6 Personen möglich. <br />
                Bitte kontaktieren Sie uns telefonisch{' '}
                <a
                  className="link"
                  style={{ display: 'inline', textDecoration: 'underline' }}
                  href="tel:+436642573207"
                >
                  +43 664 257 32 07
                </a>{' '}
                oder per E-Mail{' '}
                <a
                  className="link"
                  style={{ display: 'inline', textDecoration: 'underline' }}
                  href="mailto:office@burg-schenke.com"
                >
                  office@burg-schenke.com
                </a>
                . <br /> Vielen Dank!
                {/* Reservierungen bitte von Dienstag bis Sonntag ab 11:00 Uhr nur
                telefonisch unter: {tel.title} */}
              </span>
            </p>
            <a className="link" href={`mailto:${email}`}>
              <FontAwesomeIcon icon={faEnvelope} />
              <span>{email}</span>
            </a>
          </div>
          <div className="col-md-3 contact">
            <h4 className="uppercase fw" style={{ marginBottom: '32px' }}>
              Newsletter
            </h4>
            <Newsletter />
          </div>
        </div>
        <div className="footer__bottom container row justify-space-between">
          <div className="col-md-7">© Copyright - Burgschenke</div>
          <div className="col row justify-end">
            <Link className="link" to="/datenschutz">
              Datenschutz
            </Link>
            &nbsp;-&nbsp;
            <Link className="link" to="/impressum">
              Impressum
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
};
