import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const useTrackCtrl = () => {
  const [state, setState] = useState(false);
  // console.log(state);

  useEffect(() => {
    const setKey = ({ ctrlKey }) => {
      setState(ctrlKey);
    };
    window.addEventListener("keydown", setKey);
    window.addEventListener("keyup", setKey);

    return () => {
      window.removeEventListener("keydown", setKey);
      window.removeEventListener("keyup", setKey);
    };
  }, []);

  return state;
};

const addMarker = ({ map, lat, lng, onClick = () => {} }) => {
  const marker = new mapboxgl.Marker();
  marker.setLngLat([lng, lat]).addTo(map.current);
  const el = marker.getElement();
  el.style.color = "#c5a47e";
  // el.style.transition = '0.3s';
  el.style.cursor = "pointer";
  const svg = el.querySelector("svg");
  svg.setAttribute("viewBox", "0 0 384 512");
  svg.innerHTML =
    '<path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" class=""></path>';

  el.addEventListener("click", () => {
    onClick();
  });
  return el;
};

mapboxgl.accessToken =
  "pk.eyJ1IjoiYnVyZ3NjaGVua2UiLCJhIjoiY2tvZWRpanN1MDNsNTJ4b2doNjN4cno0ZyJ9.YykQ35xu8QfWU2h7gZgT3Q";

export const MapBox = ({
  lat,
  lng,
  zoom,
  className = "",
  style = {},
  locations = [],
  setActive = () => {},
  active,
  ...props
}) => {
  const map = useRef();
  const mapContainer = useRef(null);
  const ctrl = useTrackCtrl();
  const [marker, setMarker] = useState([]);

  useEffect(() => {
    if (marker.length <= 0) return;
    if (marker[active]) {
      marker.forEach((el) => {
        el.style.color = "#c5a47e";
      });
      marker[active].style.color = "#fff";
    }
  }, [active, marker]);

  useEffect(() => {
    if (!map.current || !mapContainer.current) return;
    map.current.flyTo({ center: [lng, lat] });
  }, [lat, lng]);

  useEffect(() => {
    if (map.current || !mapContainer.current) return;
    console.log(mapContainer);
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/burgschenke/ckoedkhzg3x3b17pbaasr4u6i",
      center: [lng + (window.innerWidth > 998 ? 0.002 : 0), lat],
      zoom,
      pitch: 50,
    });
    map.current.scrollZoom.disable();

    map.current.on("load", function () {
      if (!map.current) return;
      addMarker({ map, lat, lng });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => (map.current = null);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!map.current) return;
    if (ctrl) {
      map.current.scrollZoom.enable();
    } else {
      map.current.scrollZoom.disable();
    }
  }, [ctrl]);

  return (
    // <div className="container-fluid">
    <div
      style={{
        ...style,
        backgroundImage: `url()`,
        width: "100%",
        height: "100%",
      }}
      className={`map-container bg-img ${className}`}
      ref={mapContainer}
    ></div>
    // </div>
  );
};

MapBox.defaultProps = {
  lat: 46.5463120486058,
  lng: 13.904001552577673,
  zoom: 15,
};