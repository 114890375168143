import { useEffect } from 'react';
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';

export const useSwiper = (loaded, { className, options }) => {
  useEffect(() => {
    if (!loaded || !document.querySelector(className)) return;
    var mySwiper = new Swiper(className, options);
  }, [loaded, className, options]);
};
