import React, { useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
const mailchimpUrl =
  'https://synelution.us18.list-manage.com/subscribe/post?u=f5e6f87366b30a353cfc22fbf&amp;id=4b9ef3475e';

export const Newsletter = () => (
  <MailchimpSubscribe
    url={mailchimpUrl}
    render={({ subscribe, status, message }) => (
      <div>
        <Form onSubmitted={(formData) => subscribe(formData)}></Form>
        {status === 'sending' && <div style={{ color: 'blue' }}>sending...</div>}
        {status === 'error' && (
          <div className="error" dangerouslySetInnerHTML={{ __html: message }} />
        )}
        {status === 'success' && <div className="success">Subscribed !</div>}
      </div>
    )}
  />
);
export const Form = ({ onSubmitted }) => {
  const [input, setInput] = useState('');
  return (
    <form
      action={mailchimpUrl}
      method="post"
      onSubmit={(e) => {
        e.preventDefault();
        if (!input) return;
        onSubmitted({ EMAIL: input });
      }}
      style={{ marginBottom: '16px' }}
    >
      <input
        placeholder="Email"
        type="email"
        name="EMAIL"
        id="MERGE0"
        value={input}
        onChange={(e) => {
          setInput(e.target.value);
        }}
        autoCapitalize="off"
        autoCorrect="off"
        style={{ marginBottom: '16px' }}
      />
      <button
        type="submit"
        name="subscribe"
        id="mc-embedded-subscribe"
        className="button btn"
      >
        Subscribe
      </button>
    </form>
  );
};
