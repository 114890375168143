import React, { Component, Suspense, useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Nav } from './Nav';
import { Footer } from './Footer';
import { useAxios } from 'components/hooks';
import { API_URL } from 'config';
import Popup from 'components/Popup';

const App = React.lazy(() => import('../App'));
const OffeneStellen = React.lazy(() => import('../pages/OffeneStellen'));
const OffeneStellenSinglePage = React.lazy(() =>
  import('../pages/OffeneStellen/SinglePage')
);

const Error404 = React.lazy(() => import('./Error404'));

const AdminRedirect = () => {
  useEffect(() => {
    window.location = '/wp/admin';
  });
  return null;
};

const MainRouter = () => {
  const [loaded, setLoaded] = useState(false);
  const options = useAxios(API_URL + '/cacf/v1/options');
  // console.log(loaded);

  return (
    <>
      <Helmet>
        <html lang="de" />
        <title>Burgschenke</title>
        <meta charset="utf-8" />
        <meta name="author" content="Synelution GmbH Klagenfurt" />
        <meta name="description" content="Burgschenke" />
        <link
          rel="icon"
          type="image/png"
          href={options && options.favicon.url}
          sizes="16x16"
        />
      </Helmet>
      {options && <Nav setLoaded={setLoaded} options={options} />}
      <div
        className={`loader__container row flex-align-center justify-center ${
          loaded ? 'loaded' : ''
        }`}
      >
        <div className="loader"></div>
      </div>

      {/* <Popup /> */}
      <Suspense fallback={null}>
        <div className="main-content">
          <Switch>
            <Route exact path="/wp-admin" component={AdminRedirect} />
            <Route exact path="/admin" component={AdminRedirect} />
            <Route
              exact
              path="/404"
              component={() => <Error404 options={options} setLoaded={setLoaded} />}
            />
            <Route
              path="/"
              component={() => (
                <App options={options} setLoaded={setLoaded} loaded={loaded} />
              )}
            />
          </Switch>
        </div>
      </Suspense>
      {options && <Footer options={options} />}
    </>
  );
};

export default MainRouter;
