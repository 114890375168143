import React, { useEffect, useRef, useState } from 'react';

import { Link, NavLink } from 'react-router-dom';
import { useAxios } from 'components/hooks';
import { API_URL, PAGE_URL } from 'config';

import { MapBox } from 'components/Mapbox';

import Menu from '../../classes/Menu';
import { onDomLoad } from '../../helper';
import BookingButton from 'components/BookingButton';

const formatURL = (url) => url.replace(PAGE_URL, '');

const loadURL =
  API_URL +
  '/menus/v1/menus/2' +
  '?fields=title,url,object,classes,meta,description,ID,target';

export const Nav = ({ options }) => {
  const [open, setOpen] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [nav, setNav] = useState();
  const ref = useRef();

  const handleNavToggle = () => {
    if (!nav) return;
    setOpen(!open);
    if (!open) {
      document.body.classList.add('noscroll');
      nav.toggle('open');
    }

    if (open) {
      nav.toggle('close');
      document.body.classList.remove('noscroll');
    }
  };

  const menu = useAxios(loadURL); // ?fields=title,url,classes,meta,description

  useEffect(() => {
    const el = ref.current;
    if (!el) return;
    let nav = new Menu(document.querySelector('nav.menu'));
    setNav(nav);
  }, [ref, menu]);

  if (!options) return null;
  // const { tel, email, facebook } = options;

  return (
    <>
      <div
        className="nav-controls column flex-align-end justify-start"
        onClick={handleNavToggle}
      >
        <div
          className={`${
            open ? 'open' : ''
          } nav-button move-right delay-10 row flex-align-center justify-center pointer`}
        >
          <div className="nav-button__icon">
            <div className="nav-button__line"></div>
            <div className="nav-button__line"></div>
            <div className="nav-button__line"></div>
          </div>
        </div>
      </div>

      <header
        class={`${!open ? 'open' : ''} white row justify-left flex-align-end`}
      >
        <Link to="/" title="Zur Startseite">
          <img
            alt="logo"
            style={{ marginTop: '55px', padding: '12px' }}
            class="logo"
            src="/assets/logo.svg"
          />
        </Link>
      </header>

      <nav ref={ref} className={`menu ${open ? 'menu--open' : ''}`}>
        <div className="menu__container">
          <div className="menu__item menu__item--1" data-direction="bt">
            <div className="menu__item-inner scrollable">
              <ul className="mainmenu">
                {menu &&
                  menu.map(({ url, title, ID, object, target }) =>
                    object === 'page' ? (
                      <li className="row flex-align-stretch justify-start secondary-light mainmenu__item oswald">
                        <NavLink
                          className="row flex-align-center hover-line nav__link oswald"
                          onClick={handleNavToggle}
                          target={target}
                          key={ID}
                          to={formatURL(url)}
                        >
                          {title}
                        </NavLink>
                      </li>
                    ) : (
                      <li className="row flex-align-stretch justify-start secondary-light mainmenu__item oswald">
                        <a
                          target={target}
                          key={ID}
                          href={url}
                          className="row flex-align-center hover-line nav__link oswald"
                        >
                          {title}
                        </a>
                      </li>
                    )
                  )}
              </ul>
              <p className="label label--topleft label--vert-mirror">
                So finden Sie uns!
              </p>
              <p className="label label--bottomright label--vert">Burgschenke</p>
            </div>
          </div>

          <div className="menu__item menu__item--2" data-direction="lr">
            <div className="menu__item-inner">
              <div className="menu__item-map nav-map">
                <MapBox />
              </div>
            </div>
          </div>

          <div className="menu__item menu__item--4" data-direction="rl">
            <div className="menu__item-inner">
              <div className="contact">
                <h3 className="oswald">Rechtliches</h3>

                <NavLink
                  className="row flex-align-center hover-line nav__link nom"
                  onClick={handleNavToggle}
                  key={194}
                  to={'/impressum'}
                >
                  Impressum
                </NavLink>
                <NavLink
                  className="row flex-align-center hover-line nav__link "
                  onClick={handleNavToggle}
                  key={124}
                  to={'/datenschutz'}
                >
                  Datenschutz
                </NavLink>
              </div>
            </div>
          </div>

          <div className="menu__item menu__item--5" data-direction="tb">
            <div className="menu__item-inner move-right">
              {/* <div className="contact">
                <h3 className="oswald">Reservierung</h3>
                <BookingButton
                  content={options.reservation_text}
                  className="row flex-align-center hover-line nav__link oswald"
                />
              </div> */}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
