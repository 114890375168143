import { useEffect, useState } from 'react';
import axios from 'axios';

export function useAxios(url, log = false) {
  const [data, setData] = useState(null);

  useEffect(() => {
    async function loadData() {
      const res = await axios(url);
      if (log) console.log(res);
      if (!res.statusText === 'OK') {
        // oups! something went wrong
        console.log('oups! something went wrong', res);
        return;
      }

      const fetchData = res.data;
      setData(fetchData);
    }

    loadData();
  }, [url, log]);

  // useEffect(() => {
  // }, [data]);
  return data;
}
